@charset "UTF-8";

/* 
  Generated with canvas by Kisso industries
  @home https://canvas.kissohq.com
  @palette canvas
  @colors ----------------------------------------
  {"canvas":{"lightest":"#c3ccd9","lighter":"#8899b3","light":"#4c658d","color":"#103267","dark":"#0c264d","darker":"#081934","darkest":"#040d1a"},"cta":{"lightest":"#d9c6c3","lighter":"#b38d88","light":"#8d534c","color":"#671a10","dark":"#4d140c","darker":"#340d08","darkest":"#1a0704"},"info":{"lightest":"#cbedf7","lighter":"#98dcef","light":"#64cae7","color":"#30b8df","dark":"#248aa7","darker":"#185c70","darkest":"#0c2e38"},"warning":{"lightest":"#f5edce","lighter":"#eadb9d","light":"#e0c96c","color":"#d5b73b","dark":"#a0892c","darker":"#6b5c1e","darkest":"#352e0f"},"success":{"lightest":"#d0f1d4","lighter":"#a0e2aa","light":"#71d47f","color":"#41c554","dark":"#31943f","darker":"#21632a","darkest":"#103115"},"danger":{"lightest":"#f5ced3","lighter":"#eb9ca6","light":"#e06b7a","color":"#d6394d","dark":"#a12b3a","darker":"#6b1d27","darkest":"#360e13"}}
  @colors end ------------------------------------
*/

.gradient-canvas {
  background: #103267;
  background: -webkit-linear-gradient(to bottom, #103267, #c9b08d);
  background: linear-gradient(to bottom, #103267, #c9b08d);
}

.gradient-brush {
  background: #c9b08d;
  background: -webkit-linear-gradient(to bottom, #c9b08d, #103267);
  background: linear-gradient(to bottom, #c9b08d, #103267);
}

/* 
  Prefix
  @tag bg
  @property background-color
*/

.bg-brush-lightest,
.hover\:bg-brush-lightest:hover,
.focus\:bg-brush-lightest:focus {
  background-color: #f2ebe3;
}

.bg-brush-lighter,
.hover\:bg-brush-lighter:hover,
.focus\:bg-brush-lighter:focus {
  background-color: #e4d8c6;
}

.bg-brush-light,
.hover\:bg-brush-light:hover,
.focus\:bg-brush-light:focus {
  background-color: #d7c4aa;
}

.bg-brush,
.hover\:bg-brush:hover,
.focus\:bg-brush:focus {
  background-color: #c9b08d;
}

.bg-brush-dark,
.hover\:bg-brush-dark:hover,
.focus\:bg-brush-dark:focus {
  background-color: #97846a;
}

.bg-brush-darker,
.hover\:bg-brush-darker:hover,
.focus\:bg-brush-darker:focus {
  background-color: #655847;
}

.bg-brush-darkest,
.hover\:bg-brush-darkest:hover,
.focus\:bg-brush-darkest:focus {
  background-color: #322c23;
}

.bg-canvas-lightest,
.hover\:bg-canvas-lightest:hover,
.focus\:bg-canvas-lightest:focus {
  background-color: #c3ccd9;
}

.bg-canvas-lighter,
.hover\:bg-canvas-lighter:hover,
.focus\:bg-canvas-lighter:focus {
  background-color: #8899b3;
}

.bg-canvas-light,
.hover\:bg-canvas-light:hover,
.focus\:bg-canvas-light:focus {
  background-color: #4c658d;
}

.bg-canvas,
.hover\:bg-canvas:hover,
.focus\:bg-canvas:focus {
  background-color: #103267;
}

.bg-canvas-dark,
.hover\:bg-canvas-dark:hover,
.focus\:bg-canvas-dark:focus {
  background-color: #0c264d;
}

.bg-canvas-darker,
.hover\:bg-canvas-darker:hover,
.focus\:bg-canvas-darker:focus {
  background-color: #081934;
}

.bg-canvas-darkest,
.hover\:bg-canvas-darkest:hover,
.focus\:bg-canvas-darkest:focus {
  background-color: #040d1a;
}

.bg-cta-lightest,
.hover\:bg-cta-lightest:hover,
.focus\:bg-cta-lightest:focus {
  background-color: #d9c6c3;
}

.bg-cta-lighter,
.hover\:bg-cta-lighter:hover,
.focus\:bg-cta-lighter:focus {
  background-color: #b38d88;
}

.bg-cta-light,
.hover\:bg-cta-light:hover,
.focus\:bg-cta-light:focus {
  background-color: #8d534c;
}

.bg-cta,
.hover\:bg-cta:hover,
.focus\:bg-cta:focus {
  background-color: #671a10;
}

.bg-cta-dark,
.hover\:bg-cta-dark:hover,
.focus\:bg-cta-dark:focus {
  background-color: #4d140c;
}

.bg-cta-darker,
.hover\:bg-cta-darker:hover,
.focus\:bg-cta-darker:focus {
  background-color: #340d08;
}

.bg-cta-darkest,
.hover\:bg-cta-darkest:hover,
.focus\:bg-cta-darkest:focus {
  background-color: #1a0704;
}

.bg-info-lightest,
.hover\:bg-info-lightest:hover,
.focus\:bg-info-lightest:focus {
  background-color: #cbedf7;
}

.bg-info-lighter,
.hover\:bg-info-lighter:hover,
.focus\:bg-info-lighter:focus {
  background-color: #98dcef;
}

.bg-info-light,
.hover\:bg-info-light:hover,
.focus\:bg-info-light:focus {
  background-color: #64cae7;
}

.bg-info,
.hover\:bg-info:hover,
.focus\:bg-info:focus {
  background-color: #30b8df;
}

.bg-info-dark,
.hover\:bg-info-dark:hover,
.focus\:bg-info-dark:focus {
  background-color: #248aa7;
}

.bg-info-darker,
.hover\:bg-info-darker:hover,
.focus\:bg-info-darker:focus {
  background-color: #185c70;
}

.bg-info-darkest,
.hover\:bg-info-darkest:hover,
.focus\:bg-info-darkest:focus {
  background-color: #0c2e38;
}

.bg-warning-lightest,
.hover\:bg-warning-lightest:hover,
.focus\:bg-warning-lightest:focus {
  background-color: #f5edce;
}

.bg-warning-lighter,
.hover\:bg-warning-lighter:hover,
.focus\:bg-warning-lighter:focus {
  background-color: #eadb9d;
}

.bg-warning-light,
.hover\:bg-warning-light:hover,
.focus\:bg-warning-light:focus {
  background-color: #e0c96c;
}

.bg-warning,
.hover\:bg-warning:hover,
.focus\:bg-warning:focus {
  background-color: #d5b73b;
}

.bg-warning-dark,
.hover\:bg-warning-dark:hover,
.focus\:bg-warning-dark:focus {
  background-color: #a0892c;
}

.bg-warning-darker,
.hover\:bg-warning-darker:hover,
.focus\:bg-warning-darker:focus {
  background-color: #6b5c1e;
}

.bg-warning-darkest,
.hover\:bg-warning-darkest:hover,
.focus\:bg-warning-darkest:focus {
  background-color: #352e0f;
}

.bg-success-lightest,
.hover\:bg-success-lightest:hover,
.focus\:bg-success-lightest:focus {
  background-color: #d0f1d4;
}

.bg-success-lighter,
.hover\:bg-success-lighter:hover,
.focus\:bg-success-lighter:focus {
  background-color: #a0e2aa;
}

.bg-success-light,
.hover\:bg-success-light:hover,
.focus\:bg-success-light:focus {
  background-color: #71d47f;
}

.bg-success,
.hover\:bg-success:hover,
.focus\:bg-success:focus {
  background-color: #41c554;
}

.bg-success-dark,
.hover\:bg-success-dark:hover,
.focus\:bg-success-dark:focus {
  background-color: #31943f;
}

.bg-success-darker,
.hover\:bg-success-darker:hover,
.focus\:bg-success-darker:focus {
  background-color: #21632a;
}

.bg-success-darkest,
.hover\:bg-success-darkest:hover,
.focus\:bg-success-darkest:focus {
  background-color: #103115;
}

.bg-danger-lightest,
.hover\:bg-danger-lightest:hover,
.focus\:bg-danger-lightest:focus {
  background-color: #f5ced3;
}

.bg-danger-lighter,
.hover\:bg-danger-lighter:hover,
.focus\:bg-danger-lighter:focus {
  background-color: #eb9ca6;
}

.bg-danger-light,
.hover\:bg-danger-light:hover,
.focus\:bg-danger-light:focus {
  background-color: #e06b7a;
}

.bg-danger,
.hover\:bg-danger:hover,
.focus\:bg-danger:focus {
  background-color: #d6394d;
}

.bg-danger-dark,
.hover\:bg-danger-dark:hover,
.focus\:bg-danger-dark:focus {
  background-color: #a12b3a;
}

.bg-danger-darker,
.hover\:bg-danger-darker:hover,
.focus\:bg-danger-darker:focus {
  background-color: #6b1d27;
}

.bg-danger-darkest,
.hover\:bg-danger-darkest:hover,
.focus\:bg-danger-darkest:focus {
  background-color: #360e13;
}

/* 
  Prefix end ------------------------------------
*/

/* 
  Prefix
  @tag text
  @property color
*/

.text-brush-lightest,
.hover\:text-brush-lightest:hover,
.focus\:text-brush-lightest:focus {
  color: #f2ebe3;
}

.text-brush-lighter,
.hover\:text-brush-lighter:hover,
.focus\:text-brush-lighter:focus {
  color: #e4d8c6;
}

.text-brush-light,
.hover\:text-brush-light:hover,
.focus\:text-brush-light:focus {
  color: #d7c4aa;
}

.text-brush,
.hover\:text-brush:hover,
.focus\:text-brush:focus {
  color: #c9b08d;
}

.text-brush-dark,
.hover\:text-brush-dark:hover,
.focus\:text-brush-dark:focus {
  color: #97846a;
}

.text-brush-darker,
.hover\:text-brush-darker:hover,
.focus\:text-brush-darker:focus {
  color: #655847;
}

.text-brush-darkest,
.hover\:text-brush-darkest:hover,
.focus\:text-brush-darkest:focus {
  color: #322c23;
}

.text-canvas-lightest,
.hover\:text-canvas-lightest:hover,
.focus\:text-canvas-lightest:focus {
  color: #c3ccd9;
}

.text-canvas-lighter,
.hover\:text-canvas-lighter:hover,
.focus\:text-canvas-lighter:focus {
  color: #8899b3;
}

.text-canvas-light,
.hover\:text-canvas-light:hover,
.focus\:text-canvas-light:focus {
  color: #4c658d;
}

.text-canvas,
.hover\:text-canvas:hover,
.focus\:text-canvas:focus {
  color: #103267;
}

.text-canvas-dark,
.hover\:text-canvas-dark:hover,
.focus\:text-canvas-dark:focus {
  color: #0c264d;
}

.text-canvas-darker,
.hover\:text-canvas-darker:hover,
.focus\:text-canvas-darker:focus {
  color: #081934;
}

.text-canvas-darkest,
.hover\:text-canvas-darkest:hover,
.focus\:text-canvas-darkest:focus {
  color: #040d1a;
}

.text-cta-lightest,
.hover\:text-cta-lightest:hover,
.focus\:text-cta-lightest:focus {
  color: #d9c6c3;
}

.text-cta-lighter,
.hover\:text-cta-lighter:hover,
.focus\:text-cta-lighter:focus {
  color: #b38d88;
}

.text-cta-light,
.hover\:text-cta-light:hover,
.focus\:text-cta-light:focus {
  color: #8d534c;
}

.text-cta,
.hover\:text-cta:hover,
.focus\:text-cta:focus {
  color: #671a10;
}

.text-cta-dark,
.hover\:text-cta-dark:hover,
.focus\:text-cta-dark:focus {
  color: #4d140c;
}

.text-cta-darker,
.hover\:text-cta-darker:hover,
.focus\:text-cta-darker:focus {
  color: #340d08;
}

.text-cta-darkest,
.hover\:text-cta-darkest:hover,
.focus\:text-cta-darkest:focus {
  color: #1a0704;
}

.text-info-lightest,
.hover\:text-info-lightest:hover,
.focus\:text-info-lightest:focus {
  color: #cbedf7;
}

.text-info-lighter,
.hover\:text-info-lighter:hover,
.focus\:text-info-lighter:focus {
  color: #98dcef;
}

.text-info-light,
.hover\:text-info-light:hover,
.focus\:text-info-light:focus {
  color: #64cae7;
}

.text-info,
.hover\:text-info:hover,
.focus\:text-info:focus {
  color: #30b8df;
}

.text-info-dark,
.hover\:text-info-dark:hover,
.focus\:text-info-dark:focus {
  color: #248aa7;
}

.text-info-darker,
.hover\:text-info-darker:hover,
.focus\:text-info-darker:focus {
  color: #185c70;
}

.text-info-darkest,
.hover\:text-info-darkest:hover,
.focus\:text-info-darkest:focus {
  color: #0c2e38;
}

.text-warning-lightest,
.hover\:text-warning-lightest:hover,
.focus\:text-warning-lightest:focus {
  color: #f5edce;
}

.text-warning-lighter,
.hover\:text-warning-lighter:hover,
.focus\:text-warning-lighter:focus {
  color: #eadb9d;
}

.text-warning-light,
.hover\:text-warning-light:hover,
.focus\:text-warning-light:focus {
  color: #e0c96c;
}

.text-warning,
.hover\:text-warning:hover,
.focus\:text-warning:focus {
  color: #d5b73b;
}

.text-warning-dark,
.hover\:text-warning-dark:hover,
.focus\:text-warning-dark:focus {
  color: #a0892c;
}

.text-warning-darker,
.hover\:text-warning-darker:hover,
.focus\:text-warning-darker:focus {
  color: #6b5c1e;
}

.text-warning-darkest,
.hover\:text-warning-darkest:hover,
.focus\:text-warning-darkest:focus {
  color: #352e0f;
}

.text-success-lightest,
.hover\:text-success-lightest:hover,
.focus\:text-success-lightest:focus {
  color: #d0f1d4;
}

.text-success-lighter,
.hover\:text-success-lighter:hover,
.focus\:text-success-lighter:focus {
  color: #a0e2aa;
}

.text-success-light,
.hover\:text-success-light:hover,
.focus\:text-success-light:focus {
  color: #71d47f;
}

.text-success,
.hover\:text-success:hover,
.focus\:text-success:focus {
  color: #41c554;
}

.text-success-dark,
.hover\:text-success-dark:hover,
.focus\:text-success-dark:focus {
  color: #31943f;
}

.text-success-darker,
.hover\:text-success-darker:hover,
.focus\:text-success-darker:focus {
  color: #21632a;
}

.text-success-darkest,
.hover\:text-success-darkest:hover,
.focus\:text-success-darkest:focus {
  color: #103115;
}

.text-danger-lightest,
.hover\:text-danger-lightest:hover,
.focus\:text-danger-lightest:focus {
  color: #f5ced3;
}

.text-danger-lighter,
.hover\:text-danger-lighter:hover,
.focus\:text-danger-lighter:focus {
  color: #eb9ca6;
}

.text-danger-light,
.hover\:text-danger-light:hover,
.focus\:text-danger-light:focus {
  color: #e06b7a;
}

.text-danger,
.hover\:text-danger:hover,
.focus\:text-danger:focus {
  color: #d6394d;
}

.text-danger-dark,
.hover\:text-danger-dark:hover,
.focus\:text-danger-dark:focus {
  color: #a12b3a;
}

.text-danger-darker,
.hover\:text-danger-darker:hover,
.focus\:text-danger-darker:focus {
  color: #6b1d27;
}

.text-danger-darkest,
.hover\:text-danger-darkest:hover,
.focus\:text-danger-darkest:focus {
  color: #360e13;
}

/* 
  Prefix end ------------------------------------
*/

/* 
  Prefix
  @tag border
  @property border-color
*/

.border-brush-lightest,
.hover\:border-brush-lightest:hover,
.focus\:border-brush-lightest:focus {
  border-color: #f2ebe3;
}

.border-brush-lighter,
.hover\:border-brush-lighter:hover,
.focus\:border-brush-lighter:focus {
  border-color: #e4d8c6;
}

.border-brush-light,
.hover\:border-brush-light:hover,
.focus\:border-brush-light:focus {
  border-color: #d7c4aa;
}

.border-brush,
.hover\:border-brush:hover,
.focus\:border-brush:focus {
  border-color: #c9b08d;
}

.border-brush-dark,
.hover\:border-brush-dark:hover,
.focus\:border-brush-dark:focus {
  border-color: #97846a;
}

.border-brush-darker,
.hover\:border-brush-darker:hover,
.focus\:border-brush-darker:focus {
  border-color: #655847;
}

.border-brush-darkest,
.hover\:border-brush-darkest:hover,
.focus\:border-brush-darkest:focus {
  border-color: #322c23;
}

.border-canvas-lightest,
.hover\:border-canvas-lightest:hover,
.focus\:border-canvas-lightest:focus {
  border-color: #c3ccd9;
}

.border-canvas-lighter,
.hover\:border-canvas-lighter:hover,
.focus\:border-canvas-lighter:focus {
  border-color: #8899b3;
}

.border-canvas-light,
.hover\:border-canvas-light:hover,
.focus\:border-canvas-light:focus {
  border-color: #4c658d;
}

.border-canvas,
.hover\:border-canvas:hover,
.focus\:border-canvas:focus {
  border-color: #103267;
}

.border-canvas-dark,
.hover\:border-canvas-dark:hover,
.focus\:border-canvas-dark:focus {
  border-color: #0c264d;
}

.border-canvas-darker,
.hover\:border-canvas-darker:hover,
.focus\:border-canvas-darker:focus {
  border-color: #081934;
}

.border-canvas-darkest,
.hover\:border-canvas-darkest:hover,
.focus\:border-canvas-darkest:focus {
  border-color: #040d1a;
}

.border-cta-lightest,
.hover\:border-cta-lightest:hover,
.focus\:border-cta-lightest:focus {
  border-color: #d9c6c3;
}

.border-cta-lighter,
.hover\:border-cta-lighter:hover,
.focus\:border-cta-lighter:focus {
  border-color: #b38d88;
}

.border-cta-light,
.hover\:border-cta-light:hover,
.focus\:border-cta-light:focus {
  border-color: #8d534c;
}

.border-cta,
.hover\:border-cta:hover,
.focus\:border-cta:focus {
  border-color: #671a10;
}

.border-cta-dark,
.hover\:border-cta-dark:hover,
.focus\:border-cta-dark:focus {
  border-color: #4d140c;
}

.border-cta-darker,
.hover\:border-cta-darker:hover,
.focus\:border-cta-darker:focus {
  border-color: #340d08;
}

.border-cta-darkest,
.hover\:border-cta-darkest:hover,
.focus\:border-cta-darkest:focus {
  border-color: #1a0704;
}

.border-info-lightest,
.hover\:border-info-lightest:hover,
.focus\:border-info-lightest:focus {
  border-color: #cbedf7;
}

.border-info-lighter,
.hover\:border-info-lighter:hover,
.focus\:border-info-lighter:focus {
  border-color: #98dcef;
}

.border-info-light,
.hover\:border-info-light:hover,
.focus\:border-info-light:focus {
  border-color: #64cae7;
}

.border-info,
.hover\:border-info:hover,
.focus\:border-info:focus {
  border-color: #30b8df;
}

.border-info-dark,
.hover\:border-info-dark:hover,
.focus\:border-info-dark:focus {
  border-color: #248aa7;
}

.border-info-darker,
.hover\:border-info-darker:hover,
.focus\:border-info-darker:focus {
  border-color: #185c70;
}

.border-info-darkest,
.hover\:border-info-darkest:hover,
.focus\:border-info-darkest:focus {
  border-color: #0c2e38;
}

.border-warning-lightest,
.hover\:border-warning-lightest:hover,
.focus\:border-warning-lightest:focus {
  border-color: #f5edce;
}

.border-warning-lighter,
.hover\:border-warning-lighter:hover,
.focus\:border-warning-lighter:focus {
  border-color: #eadb9d;
}

.border-warning-light,
.hover\:border-warning-light:hover,
.focus\:border-warning-light:focus {
  border-color: #e0c96c;
}

.border-warning,
.hover\:border-warning:hover,
.focus\:border-warning:focus {
  border-color: #d5b73b;
}

.border-warning-dark,
.hover\:border-warning-dark:hover,
.focus\:border-warning-dark:focus {
  border-color: #a0892c;
}

.border-warning-darker,
.hover\:border-warning-darker:hover,
.focus\:border-warning-darker:focus {
  border-color: #6b5c1e;
}

.border-warning-darkest,
.hover\:border-warning-darkest:hover,
.focus\:border-warning-darkest:focus {
  border-color: #352e0f;
}

.border-success-lightest,
.hover\:border-success-lightest:hover,
.focus\:border-success-lightest:focus {
  border-color: #d0f1d4;
}

.border-success-lighter,
.hover\:border-success-lighter:hover,
.focus\:border-success-lighter:focus {
  border-color: #a0e2aa;
}

.border-success-light,
.hover\:border-success-light:hover,
.focus\:border-success-light:focus {
  border-color: #71d47f;
}

.border-success,
.hover\:border-success:hover,
.focus\:border-success:focus {
  border-color: #41c554;
}

.border-success-dark,
.hover\:border-success-dark:hover,
.focus\:border-success-dark:focus {
  border-color: #31943f;
}

.border-success-darker,
.hover\:border-success-darker:hover,
.focus\:border-success-darker:focus {
  border-color: #21632a;
}

.border-success-darkest,
.hover\:border-success-darkest:hover,
.focus\:border-success-darkest:focus {
  border-color: #103115;
}

.border-danger-lightest,
.hover\:border-danger-lightest:hover,
.focus\:border-danger-lightest:focus {
  border-color: #f5ced3;
}

.border-danger-lighter,
.hover\:border-danger-lighter:hover,
.focus\:border-danger-lighter:focus {
  border-color: #eb9ca6;
}

.border-danger-light,
.hover\:border-danger-light:hover,
.focus\:border-danger-light:focus {
  border-color: #e06b7a;
}

.border-danger,
.hover\:border-danger:hover,
.focus\:border-danger:focus {
  border-color: #d6394d;
}

.border-danger-dark,
.hover\:border-danger-dark:hover,
.focus\:border-danger-dark:focus {
  border-color: #a12b3a;
}

.border-danger-darker,
.hover\:border-danger-darker:hover,
.focus\:border-danger-darker:focus {
  border-color: #6b1d27;
}

.border-danger-darkest,
.hover\:border-danger-darkest:hover,
.focus\:border-danger-darkest:focus {
  border-color: #360e13;
}

/* 
  Prefix end ------------------------------------
*/
