@charset "UTF-8";

.bg-reader-menu {
  background: #f2f2f2;
}

.cursor-no-drag {
  user-drag: none;
  -webkit-user-drag: none;
}

.cursor-no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.filter-grayscale {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.transition-2 {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
}

.shadow-primary {
  box-shadow: -10px 10px 20px 0 rgba(30, 30, 30, 0.05);
}

.shadow-box {
  box-shadow: 0 10px 25px 0 rgba(49, 49, 93, 0.1);
}

.shadow-border {
  box-shadow: 0 0px 4px 0 rgba(0, 0, 0, 0.2);
}

.hover\:shadow-primary:hover {
  box-shadow: -10px 10px 20px 0 rgba(30, 30, 30, 0.05);
}

.hover\:shadow-box:hover {
  box-shadow: 0 10px 25px 0 rgba(49, 49, 93, 0.1);
}

.hover\:shadow-border:hover {
  box-shadow: 0 0px 4px 0 rgba(0, 0, 0, 0.2);
}

.focus\:shadow-primary:focus {
  box-shadow: -10px 10px 20px 0 rgba(30, 30, 30, 0.05);
}

.focus\:shadow-box:focus {
  box-shadow: 0 10px 25px 0 rgba(49, 49, 93, 0.1);
}

.focus\:shadow-border:focus {
  box-shadow: 0 0px 4px 0 rgba(0, 0, 0, 0.2);
}

.bounce-4 {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  background: #fff;
}

.bounce-4:hover {
  box-shadow: 0 0px 4px 0 rgba(0, 0, 0, 0.2);
  text-decoration: none;
  -webkit-transform: translateY(-1px);
  -moz-transform: translateY(-1px);
  -ms-transform: translateY(-1px);
  -o-transform: translateY(-1px);
  transform: translateY(-1px);
}

.scale-95 {
  -webkit-transform: scale(0.95);
  -moz-transform: scale(0.95);
  -ms-transform: scale(0.95);
  -o-transform: scale(0.95);
  transform: scale(0.95);
}

.scale-90 {
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  -ms-transform: scale(0.9);
  -o-transform: scale(0.9);
  transform: scale(0.9);
}

.scale-80 {
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
}

.scale-70 {
  -webkit-transform: scale(0.7);
  -moz-transform: scale(0.7);
  -ms-transform: scale(0.7);
  -o-transform: scale(0.7);
  transform: scale(0.7);
}

.scale-60 {
  -webkit-transform: scale(0.6);
  -moz-transform: scale(0.6);
  -ms-transform: scale(0.6);
  -o-transform: scale(0.6);
  transform: scale(0.6);
}

.scale-50 {
  -webkit-transform: scale(0.5);
  -moz-transform: scale(0.5);
  -ms-transform: scale(0.5);
  -o-transform: scale(0.5);
  transform: scale(0.5);
}

.scale-40 {
  -webkit-transform: scale(0.4);
  -moz-transform: scale(0.4);
  -ms-transform: scale(0.4);
  -o-transform: scale(0.4);
  transform: scale(0.4);
}
