@charset "UTF-8";

html,
body,
.main-section {
  height: 100%;
  width: 100%;
}

body {
  background-color: rgb(248, 249, 250);
  color: rgba(5, 2, 23, 0.8);
  min-width: 400px;
  font-family: sans-serif, museo-sans-rounded, Helvetica Neue, Arial;
  line-height: 1.625;
  font-size: 16px;
  /* -webkit-font-smoothing: antialiased; */
}
/* 
@font-face {
  font-family: sans-serif;
  src: url(http://www.font-face.com/fonts/delicious/Delicious-Roman.otf);
} */
