@charset "UTF-8";

#root {
  height: 100%;
}

#router-wrapper {
  height: 100%;
}

@media (max-width: 400px) {
}

@media (min-width: 400px) {
}

@media (min-width: 768px) {
}

@media (min-width: 990px) {
}

@media (min-width: 1200px) {
}

@media print {
  #reader-page {
    display: none;
  }
}
