@charset "UTF-8";

.toc-navigation {
  list-style: none;
  margin: 12px 0 16px 2px;
  /*border-left: 2px dotted #BBB;*/
}

.toc-nav-item {
  padding-left: 12px;
  font-size: 0.95em;
  margin-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.toc-nav-item a {
  text-decoration: none;
  color: #868686;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  white-space: nowrap;
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.toc-navigation .toc-nav-item a:hover {
  color: #333;
  /* font-weight: 600; */
}

.toc-navigation .toc-nav-item.h1 {
  margin-left: -2px;
  border-left: 5px solid;
  text-transform: uppercase;
  font-weight: 500;
}

.toc-navigation .toc-nav-item.h2 {
  margin-left: 12px;
  border-left: 2px dotted;
}

.toc-navigation .toc-nav-item.h3 {
  margin-left: 24px;
  opacity: 1;
  font-weight: 300;
  opacity: 0.8;
}

.toc-navigation .toc-nav-item.h3:before {
  content: "+";
  color: #868686;
  font-weight: 700;
}

.toc-nav-item.h2.closed~+.toc-nav-item.h3 {
  display: none;
}

.toc-nav-item.h2.closed:target~+.toc-nav-item.h3 {
  display: block;
}

@media (max-width: 400px) {
}

@media (min-width: 400px) {
}

@media (min-width: 768px) {
}

@media (min-width: 990px) {
}

@media (min-width: 1200px) {
}

@media print {
  .print-hidden {
    display: none;
  }

  .reader-page {
    display: none;
  }

  .side-bar-container {
    display: none;
  }

  .document-content-container {
    display: none;
  }
}
